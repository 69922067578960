import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Air Freight</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Air Freight</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="active">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/Air Freight.jpg" alt="" />
                </div>
                <h3 className="services-details__title-1">Air Freight</h3>
                <p className="services-details__text-1">
                  <p>
                    Our distinctive position in the logistics industry is firmly
                    rooted in several key assets: a substantial air fleet, a
                    sprawling global network, and robust partnerships with
                    leading commercial airlines. This strategic foundation
                    empowers us to provide a comprehensive spectrum of aviation
                    logistics services.
                  </p>

                  <p>
                    In the realm of time-sensitive freight delivery, the need
                    for swift and adaptable transportation options is paramount.
                    Air freight emerges as the dynamic solution, bridging
                    cities, regions, and nations across the world, enabling the
                    fulfillment of even the most stringent deadlines. This is
                    where we step in, harnessing our adeptness in delivering
                    flexible and cutting-edge aviation logistics services across
                    our extensive worldwide network. By collaborating with
                    clients, we tailor transport modes and multimodal solutions
                    that align with time, cost, and freight type constraints.
                  </p>

                  <p>
                    Central to our commitment is delivering top-notch air
                    freight service. We channel significant efforts into
                    furnishing our clients with optimal logistical solutions.
                    Our core services are amplified by avant-garde hardware,
                    infrastructure, and software, which collectively confer a
                    competitive edge to your enterprise.
                  </p>

                  <p>
                    When you entrust your air freight needs to {companyname}, you gain:
                  </p>

                  <ul>
                    <li>Benefits from our global network of hubs</li>
                    <li>
                      Access to regular air freight service scheduling with
                      web-based ordering and data access
                    </li>
                    <li>
                      Real-time Tracking and Tracing capabilities throughout the
                      journey of your shipment
                    </li>
                    <li>
                      Comprehensive paperwork and customs clearance support
                    </li>
                    <li>Unwavering assistance for seamless customs clearing</li>
                  </ul>

                  <p>
                    Our commitment transcends conventional logistics – it is
                    about enriching your business with comprehensive support,
                    bolstered by cutting-edge technology and a global network,
                    ensuring your air freight requirements are met with
                    unmatched precision and efficiency.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
