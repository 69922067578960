import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Ocean Freight</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Ocean Freight</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/Ocean Freight.jpg" alt="" />
                </div>
                <h3 className="services-details__title-1">Ocean Freight</h3>
                <p className="services-details__text-1">
                  <p>
                    With an extensive and diverse fleet, bolstered by our
                    skilled personnel operating along the world's major shipping
                    routes, we stand poised to offer our global clientele
                    frequent, secure, and cost-effective ocean freight services.
                    Our unwavering focus lies in ensuring the secure and timely
                    delivery of your maritime cargo, be it small parcels or
                    sizable shipments, across the vast oceans.
                  </p>

                  <p>
                    Beyond the logistical aspect, what sets us apart is the
                    understanding that we are not just facilitating
                    transactions, but partnering with our clients and viewing
                    the world through their lens. This perspective forms the
                    bedrock of our operations, guiding everything from
                    meticulous shipment scheduling to strategic organizational
                    decisions, efficient load distribution, integration of
                    value-added services, and the unwavering provision of
                    reliable, high-quality service – an assurance we live by.
                  </p>

                  <h3>Our Suite of Sea Freight Services:</h3>

                  <ul>
                    <li>Full Container Load (FCL)</li>
                    <li>Provision of Freight Equipment and Containers</li>
                    <li>Transshipment Services</li>
                    <li>
                      Less than Container Load (LCL) Consolidation and Export
                      Maritime Freight
                    </li>
                    <li>
                      Less than Container Load (LCL) Consolidation and Import
                      Maritime Freight
                    </li>
                    <li>
                      Frequent Weekly Vessel Service between Singapore and Hong
                      Kong with Comprehensive Shipping Coverage
                    </li>
                  </ul>

                  <p>
                    From comprehensive Full Container Load services to
                    streamlined Less than Container Load consolidation for both
                    exports and imports, our offerings are tailored to address
                    the diverse needs of our clients. Notably, our frequent
                    vessel service between Singapore and Hong Kong stands as a
                    testament to our commitment to providing expansive and
                    dependable shipping connectivity.
                  </p>

                  <p>
                    As a partner, we steadfastly uphold our promise – to deliver
                    excellence that not only meets but exceeds the expectations
                    of our valued customers.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
