import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Ocean from "./pages/Ocean";
import Air from "./pages/Air";
import Multimodal from "./pages/Multimodal";
import Railway from "./pages/Railway";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Industrial from "./pages/Industrial";
import Electronics from "./pages/Electronics";
import Cargo from "./pages/Cargo";
import High from "./pages/High";
import Retail from "./pages/Retail";

import Project from "./pages/Project";
import Track from "./pages/Track";
import Trace from "./pages/Trace";
import Inland from "./pages/Inland";
import Apparel from "./pages/Apparel";
import Automotive from "./pages/Automotive";
import Healthcare from "./pages/Healthcare";
import Third from "./pages/Third";
import Intermodal from "./pages/Intermodal";
import Warehousing from "./pages/Warehousing";
import Consolidation from "./pages/Consolidation";
import Wood from "./pages/Wood";
import Food from "./pages/Food";
import Total from "./pages/Total";
import Container from "./pages/Container";
import Whyus from "./pages/Whyus";
import Careers from "./pages/Careers";
import Incoterms from "./pages/Incoterms";
import Distance from "./pages/Distance";
import Metric from "./pages/Metric";
import Missionvision from "./pages/Missionvision";
import Faqs from "./pages/Faqs";
import Industries from "./pages/Industries";
import Requestquote from "./pages/Requestquote";
import Glossary from "./pages/Glossary";
import Energy from "./pages/Energy";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Industrial" element={<Industrial />} />
          <Route path="/Glossary" element={<Glossary />} />
          <Route path="/Energy" element={<Energy />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Ocean" element={<Ocean />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Multimodal" element={<Multimodal />} />
          <Route path="/Requestquote" element={<Requestquote />} />
          <Route path="/Railway" element={<Railway />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Inland" element={<Inland />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Electronics" element={<Electronics />} />
          <Route path="/Cargo" element={<Cargo />} />
          <Route path="/High" element={<High />} />
          <Route path="/Retail" element={<Retail />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Track" element={<Track />} />
          <Route path="/Trace" element={<Trace />} />
          <Route path="/Apparel" element={<Apparel />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/Third" element={<Third />} />
          <Route path="/Intermodal" element={<Intermodal />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Consolidation" element={<Consolidation />} />
          <Route path="/Wood" element={<Wood />} />
          <Route path="/Food" element={<Food />} />
          <Route path="/Total" element={<Total />} />
          <Route path="/Container" element={<Container />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Missionvision" element={<Missionvision />} />
          <Route path="/Incoterms" element={<Incoterms />} />
          <Route path="/Metric" element={<Metric />} />
          <Route path="/Distance" element={<Distance />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Industries" element={<Industries />} />
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
