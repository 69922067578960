import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Freight Solutions </li>
            </ul>
            <h2>Freight Solutions </h2>
          </div>
        </div>
      </section>
      <section className="cases-one">
        <div className="cases-one__bg-box" />
        <div
          className="cases-one__bg"
          style={{
            backgroundImage: "url(assets/images/backgrounds/cases-one-bg.png)",
          }}
        />
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">
              Our All Freight Solutions
            </span>
            <h2 className="section-title__title">
              Explore Our <span>Freight </span> <br />
              Solutions
            </h2>
          </div>
          <div className="cases-one__bottom">
            <div
              className="cases-one__carousel owl-carousel owl-theme thm-owl__carousel"
              data-owl-options='{
                        "loop": true,
                        "autoplay": true,
                        "margin": 30,
                        "nav": false,
                        "dots": false,
                        "smartSpeed": 500,
                        "autoplayTimeout": 10000,
                        "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"],
                        "responsive": {
                            "0": {
                                "items": 1
                            },
                            "768": {
                                "items": 1
                            },
                            "992": {
                                "items": 2
                            },
                            "1200": {
                                "items": 2
                            }
                        }
                    }'
            >
              {/*Cases one Single Start*/}
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Air Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Air" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Air">Air Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Ocean Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Ocean" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Ocean">Ocean Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Inland Transport.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Inland" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Inland">Inland Transport</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Third Party Logistics (3PL).jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Third" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Third">Third Party Logistics (3PL)</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Warehousing & Distribution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Warehousing" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Warehousing">
                          Warehousing & Distribution
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Cargo Insurance.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Cargo" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Cargo">Cargo Insurance</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Project Management.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Project" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Project">Project Management</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Total Supply Chain Solution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Total" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Total">Total Supply Chain Solution</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
