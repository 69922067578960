import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import jsPDF from "jspdf";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import axios from "axios";
import "jspdf-autotable";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [trackingNo, setTrackingNo] = useState("");
  const [items, setItems] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const generatePDF = () => {
    const doc = new jsPDF();

    const pageTitle = "linyangco.hk/Track";
    const textWidth = doc.getTextWidth(pageTitle);
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPos = pageWidth - textWidth;

    const logoWidth = 55;
    const logoHeight = 20;

    doc.setFont("Arial", "", 8);

    const headers = [
      "Container No",
      "Container Type",
      "Vessel Voyage",
      "BL No",
      "Origin",
      "Destination",
      "Departure Time",
      "Arrival Time",
    ];
    const data = items.map((item) => [
      item.containerNo,
      item.equipmentType,
      item.vesselVoyage,
      item.blNo,
      item.portOfLoading,
      item.portOfDischarge,
      item.exTimeOfDeparture,
      item.exTimeArrival,
    ]);

    const tableOptions = {
      startY: 50, // Adjusted startY to accommodate the header content
      headStyles: {
        fillColor: [91, 64, 52],
        textColor: [255, 255, 255],
      },
      styles: { textColor: [0, 0, 0], fontSize: 10 },

      didDrawPage: function (data) {
        // Footer content
        const pageCount = doc.internal.getNumberOfPages();
        const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
        const formattedDate = new Date().toLocaleDateString();
        const formattedTime = new Date().toLocaleTimeString();

        const footerText = `Page ${currentPage}  | Date: ${formattedDate} | Time: ${formattedTime}`;
        const footerX = doc.internal.pageSize.getWidth() / 2;
        const footerY = doc.internal.pageSize.getHeight() - 10;
        doc.text(footerText, footerX, footerY, { align: "center" });
      },
    };

    // Header content
    doc.setFont("Arial", "B", 18);
    doc.text("LINYANG CO. LIMITED SHIPMENT DETAILS", 105, 45, {
      align: "center",
    });

    // Logo
    doc.addImage("assets/images/logo.png", "PNG", 20, 7, logoWidth, logoHeight);

    // Title
    doc.setFont("Arial", "", 8);
    doc.setFontSize(10);
    doc.text(pageTitle, 150, 15);
    const imageCompressionOptions = {
      quality: 0.7, // Adjust this value to control image quality
    };
    // Generate the table using autoTable function with the defined options
    doc.autoTable(headers, data, tableOptions);

    doc.save("shipment-details.pdf");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitType = e.target.name; // Get the form name (e.g., "submit1")
    axios
      .get(
        `https://sgserver.net/shipmenttracking/index.php?trackingNo=${trackingNo}&submitType=${submitType}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          setItems(response.data);
          setNotFound(false);
        } else {
          setItems([]);
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Header />

      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Track Shipment</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Track Shipment</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Contact Page Start*/}
      <section className="contact-page" style={{ padding: "120px 0 20px" }}>
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Track Shipment</span>
            <h2 className="section-title__title">
              Maximize efficiency and minimize surprises – track your shipment
            </h2>
          </div>
          <div className="contact-page__form-box">
            <form onSubmit={handleSubmit} name="linyangco">
              <div className="row g-3">
                <div className=" col-lg-6">
                  <input
                    type="text"
                    value={trackingNo}
                    onChange={(e) => setTrackingNo(e.target.value)}
                    placeholder="Enter BL No. or Container No."
                    className="form-control w-100 py-3"
                  />
                  <span />
                </div>

                <div className=" col-lg-6  text-lg-center">
                  <button
                    name="submit"
                    className="thm-btn contact-page__form-btn"
                    type="submit"
                  >
                    Track Shipment
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <div className="container p-4">
        {" "}
        {items.length > 0 && (
          <div className="table-wrapper">
            <table style={{ width: " -webkit-fill-available" }}>
              <thead>
                <tr>
                  <th >Container No</th>
                  
                  <th>Type</th>
                  <th>Port Of Loading</th>
                  <th>Port Of Destination</th>
                  <th>Estimated Departure Time</th>
                  <th>Estimated Arrival Time</th>
                  <th>Vessel Voyage</th>
                  
                </tr>
              </thead>
              <tbody>
                
                {notFound ? (
                  <tr>
                    <td colSpan="3">No items found with the given name.</td>
                  </tr>
                ) : (
                  items.map((item) => (
                    <tr key={item.id}>
                      <td>{item.containerNo}
                      <Link
                          to={{
                            pathname: `/Trace`,
                            search: `id=${item.blNo}&contid=${item.containerNo}`,
                          }}
                          className="url"
                        ><span style={{color:"red"}}>
                          Details</span>
                        </Link>
                      </td>

                      <td>{item.equipmentType}</td>
                      <td>{item.portOfLoading}</td>
                      <td>{item.portOfDischarge}</td>
                      <td>{item.exTimeOfDeparture}</td>
                      <td>{item.exTimeArrival}</td>
                      <td>{item.vesselVoyage}</td>

                     
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <div className="text-center p-2">
            <a onClick={generatePDF}>
                          {" "}
                          <img src="assets/images/pdf.png" height="90px" />
                        </a>
            </div>
          </div>
         
        )}
        {items.length === 0 && notFound && (
          <p>
            No Air/Sea Way BL available. Please provide the valid Container/BL
            no. to get the results
          </p>
        )}
      </div>

      <Footer />
    </>
  );
}
