import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Electronics</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Electronics</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Electronics.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Electronics Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      The significance of handling expensive and delicate
                      electronic equipment is underscored by the specialized
                      measures it demands throughout handling, transit, and
                      brokerage processes. At the core of our operations, we
                      hold a deep reverence for company confidentiality and
                      national security. This ethos is intricately woven into
                      our services, catering to the intricate needs of internal
                      cargo build-up, meticulous screening, customs brokerage,
                      and even on-site surveillance and security protocols to
                      safeguard your interests.
                    </p>

                    <p>
                      In a fiercely competitive market landscape, we employ
                      strategic tactics to ensure optimal service. Our preferred
                      carrier program, bolstered by blocked space agreements
                      (BSAs), secures access to coveted destinations. This,
                      coupled with our deployment of chartered aircraft,
                      expedited marine freight, and a spectrum of multi-modal
                      alternatives, forms our arsenal against cost escalation
                      and extended lead times.
                    </p>

                    <p>
                      Within the electronics sector, we don't just offer a
                      service – we emerge as the definitive partner. Whether
                      it's navigating the intricacies of raw material logistics
                      or facilitating the seamless transit of finished products,
                      we stand as the go-to solution. Our commitment is singular
                      – ensuring the immaculate transport of electronic
                      essentials while fostering growth and success within the
                      industry.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
