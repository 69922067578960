import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Energy </li>
            </ul>
            <h2>Energy </h2>
          </div>
        </div>
      </section>
      <section className="news-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="news-details__left">
                <div className="news-details__img">
                  <img src="assets/images/Energy.jpg" alt="" />
                </div>
                <div className="news-details__content">
                  <h3 className="news-details__title">Energy</h3>
                  <p>
                    If you're seeking a logistics partner that embodies
                    reliability and innovation, our organization stands out as
                    the ultimate choice. Commodities like solar panels, wind
                    turbines, and biofuels, which are vulnerable to temperature
                    and climatic variables, demand a logistics provider that is
                    not only cutting-edge but also highly proficient. For the
                    transportation of such delicate yet crucial products, Pride
                    World Shipping Pte. Ltd. emerges as the perfect solution –
                    S's swiftest-growing tech-driven logistics service.
                  </p>

                  <p>
                    Our customized energy logistics solutions extend beyond
                    transportation; they encompass a holistic approach to
                    enhancing your supply chain efficiency. From meticulous
                    product testing and spare part storage to efficient
                    warehousing and packaging, we offer comprehensive solutions
                    to elevate your operations.
                  </p>

                  <p>
                    In the thriving renewable energy market, where precision and
                    cost-effectiveness are paramount, manufacturers of solar
                    panels and similar resources seek to optimize logistics
                    expenses without compromising delivery accuracy. Among the
                    myriad challenges faced, customs clearance at port terminals
                    is a notable hurdle that often impacts shipment success. Our
                    adept team is well-equipped to navigate these challenges,
                    ensuring the smooth transit of your valuable consignments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Other Industries</h3>
                  <ul className="sidebar__category-list list-unstyled">
                  <li className=""><Link to="/Apparel">Apparel <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Healthcare">Healthcare <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Food">Food & Beverages <span className="fas fa-angle-double-right" /></Link></li>
                    <li className="active "><Link to="/Energy">Energy <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Automotive">Automotive <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Electronics">Electronics <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Retail">Retail <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/High">High Tech <span className="fas fa-angle-double-right" /></Link></li>
                    <li className=" "><Link to="/Industrial">Industrial <span className="fas fa-angle-double-right" /></Link></li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-five">
        <div className="container">
          <div className="services-five__inner">
            <div className="section-title-three text-center">
              <div className="section-title-three__sub-title-box">
                <p className="section-title-three__sub-title">
                  OUR INDUSTRY SOLUTIONS
                </p>
                <div className="section-title-three__shape" />
              </div>
              <h2 className="section-title-three__title">
                Key Sectors of Phoenix Logistics Limited
              </h2>
            </div>
            <div
              className="owl-carousel thm-owl__carousel--range owl-theme services-five__carousel"
              data-owl-options='{"loop": false,
                    "nav": false,
                    "autoWidth": true,
                    "navText": ["<span class=\"fa fa-angle-left\"></span>","<span class=\"fa fa-angle-right\"></span>"],
                    "dots": false,
                    "margin": 10,
                    "items": 1,
                    "smartSpeed": 700,
                    "responsive": {
                        "0": {
                            "margin": 30,
                            "items": 1,
                            "autoWidth": false
                        },
                        "768": {
                            "margin": 30,
                            "items": 2,
                            "autoWidth": false
                        },
                        "992": {
                            "margin": 30,
                            "items": 3
                        },
                        "1200": {
                            "margin": 30,
                            "items": 4
                        }
                    }}'
            >
              {/*Services Five Single Start*/}
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Apparel.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Apparel">Apparel</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Apparel">Apparel</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Healthcare.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Healthcare">Healthcare</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Healthcare">Healthcare</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Food & Beverages.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Food">Food & Beverages</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Food">Food & Beverages</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Energy.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Energy">Energy</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Energy">Energy</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Automotive.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Automotive">Automotive</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Automotive">Automotive</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Electronics.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Electronics">Electronics</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Electronics">Electronics</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Retail.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Retail">Retail</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Retail">Retail</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/High Tech.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/High">High Tech</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/High">High Tech</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="services-five__single">
                  <div className="services-five__img-box">
                    <div className="services-five__img">
                      <img
                        src="assets/images/Industrial.jpg"
                        alt=""
                        style={{ height: "250px" }}
                      />
                      <div className="services-five__hover-content">
                        <div className="services-five__hover-content-icon">
                          <span className="fa fa-globe" />
                        </div>
                        <h4 className="services-five__hover-content-title">
                          <Link to="/Industrial">Industrial</Link>
                        </h4>
                        <p className="services-five__hover-content-text"></p>
                      </div>
                    </div>
                    <div className="services-five__title-box">
                      <h4 className="services-five__title">
                        <Link to="/Industrial">Industrial</Link>
                      </h4>
                      <div className="services-five__icon">
                        <span className="fa fa-globe" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thm-owl__carousel--range__input">
              <input type="text" defaultValue name="range" />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
