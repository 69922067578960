import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>About Us </li>
            </ul>
            <h2>About Us </h2>
          </div>
        </div>
      </section>
      <section className="about-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="about-one__left pt-4 mt-4">
                <div className="about-one__img">
                  <img src="assets/images/About1.jpg" alt="" />
                </div>
                <div className="pt-4 mt-4 ">
                  <Link to="/About" className="thm-btn contact-one__form-btn">
                    Learn More About Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-one__right">
                <div className="section-title text-left">
                  <span className="section-title__tagline">
                    welcome to {companyname}
                  </span>
                  <h2 className="section-title__title">
                    Turning Challenges into Opportunities..
                  </h2>
                </div>
                <div className="about-one__experience-and-points">
                  <div className="about-one__experience-points-box">
                    <p className="about-one__experience-points-text">
                      Our primary objective revolves around meeting the unique
                      requirements of every client we serve in the realm of
                      logistics services. Our company, {companyname}, is
                      committed to upholding the highest standards of quality
                      across various dimensions in the logistics industry. These
                      encompass effective communication, consistent reliability
                      in shipping and transport, unwavering transparency in
                      supply chain management, gracious courtesies in dealing
                      with clients, genuine empathy for the challenges of
                      logistics, swift responsiveness to changing needs,
                      constant availability to address inquiries, and robust
                      security measures to safeguard valuable cargo. Our
                      unyielding determination lies in our continuous efforts to
                      refine and elevate our operational processes and
                      technological infrastructure specifically tailored to
                      logistics services. By doing so, we diligently strive to
                      fulfill our overarching goal of providing exceptional
                      logistics solutions to our clients.
                    </p>
                    <div className="about-one__experience-points">
                      <ul className="about-one__experience-points-list list-unstyled">
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Reliability</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Efficiency</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Global Reach</p>
                          </div>
                        </li>
                      </ul>
                      <ul className="about-one__experience-points-list list-unstyled">
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Expertise</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Safety and Security</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Cost-Effective Solutions</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cases-one">
        <div className="cases-one__bg-box" />
        <div
          className="cases-one__bg"
          style={{
            backgroundImage: "url(assets/images/backgrounds/cases-one-bg.png)",
          }}
        />
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">
              Our All Freight Solutions
            </span>
            <h2 className="section-title__title">
              Explore Our <span>Freight </span> <br />
              Solutions
            </h2>
          </div>
          <div className="cases-one__bottom">
            <div
              className="cases-one__carousel owl-carousel owl-theme thm-owl__carousel"
              data-owl-options='{
                        "loop": true,
                        "autoplay": true,
                        "margin": 30,
                        "nav": false,
                        "dots": false,
                        "smartSpeed": 500,
                        "autoplayTimeout": 10000,
                        "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"],
                        "responsive": {
                            "0": {
                                "items": 1
                            },
                            "768": {
                                "items": 1
                            },
                            "992": {
                                "items": 2
                            },
                            "1200": {
                                "items": 2
                            }
                        }
                    }'
            >
              {/*Cases one Single Start*/}
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Air Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Air" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Air">Air Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Ocean Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Ocean" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Ocean">Ocean Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Inland Transport.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Inland" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Inland">Inland Transport</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Third Party Logistics (3PL).jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Third" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Third">Third Party Logistics (3PL)</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Warehousing & Distribution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Warehousing" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Warehousing">
                          Warehousing & Distribution
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Cargo Insurance.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Cargo" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Cargo">Cargo Insurance</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Project Management.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Project" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Project">Project Management</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Total Supply Chain Solution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Total" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Total">Total Supply Chain Solution</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
