import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Apparel</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Apparel</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Apparel.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Apparel Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      Within the garment industry, the significance of an
                      effective supply chain management strategy cannot be
                      overstated. The intricate interplay of supply chain
                      activities has become a linchpin for success in this
                      sector. Senior management recognizes this fundamental
                      truth and places the establishment of a robust supply
                      chain management system at the forefront of their
                      priorities.
                    </p>
                    <p>
                      In our organization, the intricate dance of resource
                      management involves the seamless movement of these vital
                      components from one point to another. This orchestration
                      spans various parties, departments, and geographic
                      locations. The crux of our approach lies in ensuring
                      resource availability in optimal quantities precisely when
                      required. Achieving this equilibrium involves minimizing
                      process costs while maximizing efficiency.
                    </p>
                    <p>
                      Unlike our proactive approach, many logistics companies
                      grapple with challenges they acknowledge yet struggle to
                      effectively address. It's imperative for organizations to
                      meticulously strategize their supply networks, as the
                      success of the garment industry hinges on the meticulous
                      execution of supply chain management plans. 
                    </p>
                   
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
