import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />

      {/*Page Header Start*/}
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Contact</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Contact Page Start*/}
      <section className="contact-page">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Contact with us</span>
            <h2 className="section-title__title">
              Feel Free to Get <span>in</span> <br /> Touch with us
            </h2>
          </div>
          <div className="contact-page__form-box">
            <form
              id="contactForm"
              className="contact-page__form contact-form-validated"
              action="/php/thankyou-contact.php "
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="row g-3">
                <div className="col-6">
                  <div className="contact-page__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="contact-page__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-page__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-page__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-page__form-input-box">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="thm-btn contact-page__form-btn"
                    type="submit"
                    defaultValue=""
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <h5 style={{ color: "red" }}>{result}</h5>
          </div>
        </div>
      </section>
      {/*Contact Page End*/}
      {/*Contact Details Start*/}
      <section className="contact-details">
        <div className="container">
          <div className="contact-details__inner">
            <div className="contact-details__inner-content">
              <div className="contact-details__shape-1 float-bob-y">
                <img
                  src="assets/images/shapes/contact-details-shape-1.png"
                  alt=""
                />
              </div>
              <ul className="list-unstyled contact-details__list">
                <li>
                  <div className="icon">
                    <span className="fa fa-globe" />
                  </div>
                  <div className="content">
                    <p>Website</p>
                    <h4>{companyurl}</h4>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-mailbox" />
                  </div>
                  <div className="content">
                    <p>Write Email</p>
                    <h4>
                      <a href="mailto:{companyemail}">{companyemail}</a>
                    </h4>
                  </div>
                </li>
                <li>
                  <div className="icon">
                    <span className="icon-maps-and-flags" />
                  </div>
                  <div className="content">
                    <p>Visit Center</p>
                    <h4>Kwun Tong Kowloon, Hong Kong</h4>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*Contact Details End*/}
      {/*Google Map Two Start*/}
      <section className="google-map-two">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14764.498889037255!2d114.20587120996761!3d22.31112235622866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34040134cb864a2f%3A0xfc77c4a7a80e8e72!2sManning%20Industrial%20Building%2C%20116-118%20How%20Ming%20St%2C%20Kwun%20Tong%2C%20Hong%20Kong!5e0!3m2!1sen!2sin!4v1694675061101!5m2!1sen!2sin"
          className="google-map__two"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
      {/*Google Map Two End*/}
      <Footer />
    </>
  );
}
