import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  
  function handleMobileNavClick() {
    const mobileNavWrapper = document.querySelector(".mobile-nav__wrapper");
    const mainHeader = document.querySelector(".mainheader"); // Replace with your actual class

    if (mobileNavWrapper && mainHeader) {
      mobileNavWrapper.classList.remove("expanded");
      mainHeader.classList.remove("locked"); // Remove the 'locked' class
    }
  }

  const mobileNavToggler = document.querySelector(".mobile-nav__toggler");

  if (mobileNavToggler) {
    mobileNavToggler.addEventListener("click", handleMobileNavClick);
  }
  useEffect(() => {
    const mobileNavToggler = document.querySelector(".mobile-nav__toggler");
    const mainHeader = document.querySelector("#mainheader");
    const mobileNavWrapper = document.querySelector(".mobile-nav__wrapper");

    if (mobileNavToggler && mainHeader && mobileNavWrapper) {
      mobileNavToggler.addEventListener("click", (e) => {
        e.preventDefault();
        mobileNavWrapper.classList.toggle("expanded");
        mainHeader.classList.toggle("locked");
      });
    }

    const mainMenuList = document.querySelector(".main-menu__list");
    const mobileNavContainer = document.querySelector(".mobile-nav__container");
    const stickyHeaderContent = document.querySelector(
      ".sticky-header__content"
    );

    if (mainMenuList && mobileNavContainer) {
      // Clone and append the main-menu__list to the mobile-nav__container
      const navContent = mainMenuList.outerHTML;
      mobileNavContainer.innerHTML = navContent;
    }

    if (stickyHeaderContent) {
      // Clone and append the content of .main-menu to .sticky-header__content
      const navContent = document.querySelector(".main-menu").innerHTML;
      stickyHeaderContent.innerHTML = navContent;
    }

    const dropdownAnchors = document.querySelectorAll(
      ".mobile-nav__container .main-menu__list .dropdown > a"
    );

    dropdownAnchors.forEach((anchor) => {
      const toggleBtn = document.createElement("button");
      toggleBtn.setAttribute("aria-label", "dropdown toggler");
      toggleBtn.innerHTML = '<i class="fa fa-angle-down"></i>';
      anchor.appendChild(toggleBtn);

      toggleBtn.addEventListener("click", (e) => {
        e.preventDefault();
        const self = anchor.querySelector("button");
        self.classList.toggle("expanded");
        anchor.parentElement.classList.toggle("expanded");
        anchor.parentElement.parentElement
          .querySelector("ul")
          .classList.toggle("expanded");
      });
    });
  }, []);

  return (
    <>
      <div className=" " id="mainheader">
        <header className="main-header">
          <nav className="main-menu">
            <div className="main-menu__wrapper">
              <div className="main-menu__wrapper-inner">
                <div className="main-menu__logo">
                  <Link to="/Home">
                    <img
                      src="assets/images/logo.png"
                      alt=""
                      style={{ height: "70px" }}
                    />
                  </Link>
                </div>
                <div className="main-menu__btn-box">
                  <Link to="/Track">Track Your Shipment</Link>
                </div>
                <div className="main-menu__wrapper-inner-content">
                  <div className="main-menu__top">
                    <div className="main-menu__top-inner">
                      <div className="main-menu__top-left">
                        <ul className="list-unstyled main-menu__contact-list">
                          <li>
                            <div className="icon">
                              <i className="fas fa-envelope" />
                            </div>
                            <div className="text">
                              <p>
                                <Link to="mailto:{companyemail}">
                                  {companyemail}
                                </Link>
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i className="fas fa-map-marker" />
                            </div>
                            <div className="text">
                              <p>{companyaddress}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="main-menu__top-right"></div>
                    </div>
                  </div>
                  <div className="main-menu__bottom">
                    <div className="main-menu__bottom-inner">
                      <div className="main-menu__main-menu-box">
                        <Link to="#" className="mobile-nav__toggler">
                          <i className="fa fa-bars" />
                        </Link>
                        <ul className="main-menu__list">
                          <li className="dropdown  megamenu">
                            <Link to="/Home">Home </Link>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Company Profile</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/About">Introduction</Link>
                              </li>
                              <li>
                                <Link to="/Whyus">Why Choose Us</Link>
                              </li>
                              <li>
                                <Link to="/Missionvision">
                                  Mission Statement
                                </Link>
                              </li>
                              <li>
                                <Link to="/Faqs">FAQ's</Link>
                              </li>
                              <li>
                                <Link to="/Careers">Join Us</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Freight Solutions</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/Air">Air Freight</Link>
                              </li>
                              <li>
                                <Link to="/Ocean">Ocean Freight</Link>
                              </li>
                              <li>
                                <Link to="/Inland">Inland Transport</Link>
                              </li>
                              <li>
                                <Link to="/Third">
                                  Third Party Logistics (3PL)
                                </Link>
                              </li>
                              <li>
                                <Link to="/Warehousing">
                                  Warehousing & Distribution
                                </Link>
                              </li>
                              <li>
                                <Link to="/Air">Cargo Insurance</Link>
                              </li>
                              <li>
                                <Link to="/Project">Project Management</Link>
                              </li>
                              <li>
                                <Link to="/Total">
                                  Total Supply Chain Solution
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Industry Sectors</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/Automotive">Automotive</Link>
                              </li>
                              <li>
                                <Link to="/Electronics">Electronics</Link>
                              </li>
                              <li>
                                <Link to="/Retail">Retail</Link>
                              </li>
                              <li>
                                <Link to="/High">High Tech</Link>
                              </li>
                              <li>
                                <Link to="/Industrial">Industrial</Link>
                              </li>
                              <li>
                                <Link to="/Apparel">Apparel</Link>
                              </li>
                              <li>
                                <Link to="/Healthcare">Healthcare</Link>
                              </li>
                              <li>
                                <Link to="/Food">Food & Beverages</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="dropdown">
                            <Link to="#">Resources</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/Container">Container Types</Link>
                              </li>
                              <li>
                                <Link to="/Glossary">Glossary</Link>
                              </li>
                              <li>
                                <Link to="/Incoterms">Shipping Incoterms</Link>
                              </li>
                              <li>
                                <Link to="/Consolidation">
                                  Import Export Consolidation
                                </Link>
                              </li>
                              <li>
                                <Link to="/Privacy">Privacy Policy</Link>
                              </li>
                              <li>
                                <Link to="/Terms">Terms & Conditions</Link>
                              </li>
                              <li>
                                <Link to="/Getquote">Get Quote</Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/Contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="stricky-header stricked-menu main-menu">
          <div className="sticky-header__content" />
          {/* /.sticky-header__content */}
        </div>
      </div>
    </>
  );
};

export default Header;
