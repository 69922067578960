import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Inland Transport</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Inland Transport</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/Inland Transport.jpg" alt="" />
                </div>
                <h3 className="services-details__title-1">Inland Transport</h3>
                <p className="services-details__text-1">
                  <p>
                    In the intricate landscape of international trade, the role
                    of inland transportation stands as a linchpin. It's the
                    initial step that sets your goods in motion, ensuring they
                    reach the sea port for their global voyage. Similarly, after
                    traversing the vast oceans, the need for meticulous inland
                    transportation arises once again to guide your cargo to its
                    designated warehouse.
                  </p>

                  <p>
                    This comprehensive insight into the significance of inland
                    shipping, coupled with our commitment at {companyname}, forms the crux of this discussion. We are poised to
                    ensure your cargo's seamless journey from door to door,
                    eliminating logistical barriers and facilitating shipping
                    for entities, particularly SMEs, who require it.
                  </p>

                  <p>
                  {companyname} was born from the aspiration to
                    alleviate logistical complexities and streamline shipping
                    processes, particularly for small and medium-sized
                    enterprises. Central to this endeavor is the mastery of
                    skill in transporting goods from point A to point B
                    seamlessly.
                  </p>

                  <p>
                    While you may have approached us with the intent of global
                    maritime shipping, it's essential to recognize that this is
                    just one facet of the journey. Numerous pivotal steps work
                    in tandem to ensure your cargo's triumphant arrival at its
                    intended destination.
                  </p>

                  <p>
                    Navigating this intricate web might initially appear
                    daunting. Especially when incoterms are involved, you might
                    relinquish control over your cargo until it reaches the
                    destination port. The involvement of multiple parties – from
                    shippers to suppliers, and even separate shipping companies
                    and ocean lines chosen by your supplier – can often
                    introduce confusion, delays, mishandling, and communication
                    gaps. The ramifications of these challenges might manifest
                    in unforeseen expenses incurred due to delays.
                  </p>

                  <p>
                    Effective inland transportation stands as the remedy to
                    this, and this is where we come into play. We take immense
                    pride in offering our assistance, streamlining the process,
                    and serving as a reliable partner to ensure that your
                    cargo's journey is both seamless and successful.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
