import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Why Us </li>
            </ul>
            <h2>Why Us </h2>
          </div>
        </div>
      </section>
     
      <section className="why-choose-one" style={{padding:"120px 0 30px"}}>
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="why-choose-one__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Our benefits</span>
                  <h2 className="section-title__title">
                    Why <span>Choose</span> <br />
                    {companyname}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="why-choose-one__right">
                <ul className="why-choose-one__list list-unstyled">
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fa fa-clock" />
                      </div>
                      <div className="title">
                        <h3>
                          24/7 Support
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fa fa-users" />
                      </div>
                      <div className="title">
                        <h3>
                          Team Work
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fas fa-award" />
                      </div>
                      <div className="title">
                        <h3>
                          Services excellence
                        </h3>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-one">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Our Industry Sectors</span>
            <h2 className="section-title__title">
              Explore Our <span>Industry</span> <br />
               Sectors
            </h2>
          </div>
          <div className="row">
            {/*Blog One Single Start*/}
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Automotive.jpg" alt="" />
                    <Link to="/Automotive">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Automotive">
                      Automotive
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Electronics.jpg" alt="" />
                    <Link to="/Electronics">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Electronics">
                      Electronics
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Retail.jpg" alt="" />
                    <Link to="/Retail">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Retail">
                      Retail
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/High Tech.jpg" alt="" />
                    <Link to="/High">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/High">
                      High Tech
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Industrial.jpg" alt="" />
                    <Link to="/Industrial">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Industrial">
                      Industrial
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Apparel.jpg" alt="" />
                    <Link to="/Apparel">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Apparel">
                      Apparel
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
