import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Warehousing & Distribution</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Warehousing & Distribution</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img
                    src="assets/images/Warehousing & Distribution.jpg"
                    alt=""
                  />
                </div>
                <h3 className="services-details__title-1">
                  Warehousing & Distribution
                </h3>
                <p className="services-details__text-1">
                  <p>
                    The synergy of specialized warehouse solutions and
                    strategically positioned distribution hubs serves to amplify
                    your operational efficiency and flexibility. Whether you
                    seek to establish a comprehensive global supply chain or
                    simply manage fluctuations in inventory during seasonal
                    spikes, we stand ready to offer our assistance.
                  </p>

                  <p>
                    With storage, distribution, and consolidation centers
                    spanning a colossal expanse exceeding two million square
                    meters, our offerings are impeccably positioned to cater to
                    a diverse spectrum of industries. It goes beyond the surface
                    – our value proposition extends from supply chain visibility
                    and inventory management to encompass an unparalleled level
                    of service.
                  </p>

                  <p>
                    Our warehousing and distribution facilities are
                    strategically nestled in proximity to vital ports and hub
                    activities. They serve as integral components of a
                    meticulously crafted network, adept at fulfilling the
                    demands of cross-border transportation, just-in-time
                    production requisites, final mile delivery, after-sales
                    logistics, and even reverse logistics.
                  </p>

                  <p>
                    Be it temperature-controlled or integrated clean room supply
                    chains, we stand poised to deliver solutions that match
                    diverse needs. Furthermore, an array of value-added services
                    awaits both producers and consumers, adding an extra layer
                    of distinction to our offerings.
                  </p>

                  <h3>Our Spectrum of Offerings:</h3>

                  <ul>
                    <li>
                      Distribution Facilities: Strategically designed
                      distribution centers cater to your needs spanning
                      distribution, storage, reverse logistics, and inventory
                      management.
                    </li>
                    <li>
                      Storage Facility Consulting: We provide insights and
                      recommendations to optimize warehouse site selection,
                      enhancing inventory control and distribution through
                      strategic infrastructure and network localization.
                    </li>
                    <li>
                      Temperature Controlled Warehouse Facilities: These
                      facilities seamlessly integrate into
                      temperature-controlled supply chains, addressing
                      regulatory requirements across diverse sectors.
                    </li>
                    <li>
                      Consolidation Center (CFS) Services: Enhance shipping
                      efficiency, planning, and forecasting through our
                      consolidation services, a pivotal facet of a refined
                      supply chain. This includes Origin Cargo Management and
                      Less-Container-Load (LCL) services.
                    </li>
                  </ul>

                  <p>
                    At {companyname}, our commitment to transforming
                    logistical landscapes is underpinned by the fusion of
                    expertise, strategic positioning, and a versatile suite of
                    services that cater to diverse needs.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
