import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage: "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Frequently Asked Questions</li>
            </ul>
            <h2>Frequently Asked Questions</h2>
          </div>
        </div>
      </section>
      <section className="faq-search-box">
        <div className="container">
          <div className="faq-search-box__inner">
            <div className="faq-search-box__shape float-bob-x"></div>
            <div className="faq-search-box__left">
              <h3 className="faq-search-box__title text-center">
                Frequently Asked Questions
              </h3>
            </div>
          </div>
        </div>
      </section>
      {/*Faq Search Box End*/}
      {/*Faq Page Start*/}
      <section className="faq-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="faq-page__right">
                <div
                  className="accrodion-grp"
                  data-grp-name="faq-one-accrodion"
                >
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>What is a freight forwarding company?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          A freight forwarding company is a third-party
                          logistics provider that specializes in coordinating
                          the transportation and shipment of goods on behalf of
                          shippers or businesses. They manage various aspects of
                          the supply chain, including shipping, customs
                          clearance, documentation, and logistics
                        </p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>
                        How do freight forwarders handle shipping documentation?
                      </h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Freight forwarders are responsible for preparing and
                          managing shipping documentation, including bills of
                          lading, invoices, packing lists, certificates of
                          origin, and customs declarations. Accurate
                          documentation is crucial for efficient shipment and
                          customs clearance.
                        </p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>
                        {" "}
                        What services do freight forwarding companies offer?
                      </h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>
                          Freight forwarding companies offer a range of
                          services, including international and domestic
                          shipping, customs brokerage, cargo insurance,
                          warehousing, order tracking, documentation
                          preparation, and freight consolidation. They ensure
                          smooth movement of goods from the point of origin to
                          the final destination.
                        </p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>Do freight forwarders provide cargo insurance?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Many freight forwarders offer cargo insurance to protect shipments against loss or damage during transit. However, insurance terms and coverage may vary, so it's important to discuss insurance options with your chosen forwarder.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4> How do freight forwarders help with customs clearance?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Freight forwarders assist in preparing the necessary customs documentation, ensuring compliance with regulations and tariffs, and handling communication with customs authorities. They help facilitate the smooth clearance of goods across international borders.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>Can freight forwarders handle oversized or hazardous cargo?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Yes, some freight forwarders specialize in handling specialized cargo such as oversized, heavy, or hazardous materials. These forwarders have expertise in managing the unique requirements and regulations associated with such shipments.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4> How do I choose the right freight forwarding company?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>When choosing a freight forwarder, consider factors such as their experience, industry reputation, global network, services offered, customer support, and cost-effectiveness. It's important to select a company that aligns with your shipping needs and requirements.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4> What is door-to-door shipping?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>Door-to-door shipping is a service where the freight forwarder arranges transportation from the shipper's location to the recipient's location. This includes pickup, transportation, customs clearance, and final delivery.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                  <div className="accrodion">
                    <div className="accrodion-title">
                      <h4>What is the difference between a freight forwarder and a carrier?</h4>
                    </div>
                    <div className="accrodion-content">
                      <div className="inner">
                        <p>A freight forwarder acts as an intermediary between shippers and carriers. They coordinate logistics, documentation, and customs processes. Carriers are transportation companies (e.g., shipping lines, airlines, trucking companies) that physically move the goods from one location to another.</p>
                      </div>
                      {/* /.inner */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
