import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Third Party Logistics (3PL)</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Third Party Logistics (3PL)</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img
                    src="assets/images/Third Party Logistics (3PL).jpg"
                    alt=""
                  />
                </div>
                <h3 className="services-details__title-1">
                  Third Party Logistics (3PL)
                </h3>
                <p className="services-details__text-1">
                  <p>
                    Emphasizing our commitment to connectivity, our specialized
                    integrated services empower our clients with unparalleled
                    visibility.
                  </p>

                  <p>
                    At {companyname}, our perspective extends beyond
                    mere cargo movement. It's a profound sense of responsibility
                    that drives us to curate a diverse array of products sourced
                    from around the globe. This dedication stems from our belief
                    in unlocking the full potential of the global marketplace.
                    Our endeavors transcend logistical intricacies – they
                    encompass the harmonious facilitation of goods that fuels
                    international commerce. Procurement management, seamless
                    material movement, and the art of commodity storage are our
                    canvas through which we strive to optimize our clients'
                    supply chains, effecting the most effective and efficient
                    solutions.
                  </p>

                  <p>
                    Ultimately, our role transcends mere logistics; it's about
                    sustaining connectivity and visibility that resonate with
                    your business.
                  </p>

                  <h3>Dangerous Goods</h3>
                  <p>Our 3PL Solutions Encompass:</p>

                  <ul>
                    <li>Order Fulfillment</li>
                    <li>Cross-docking</li>
                    <li>Cross Border Trucking and Last Mile Delivery</li>
                    <li>Value-added Services</li>
                    <li>Bonded and Non-Bonded Warehousing</li>
                  </ul>

                  <p>
                    At {companyname}, we are more than logistics –
                    we're the bridge that fosters global trade, underpinned by a
                    commitment to excellence, a profound understanding of
                    connectivity, and a dedication to realizing your business's
                    full potential.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
