import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <section className="main-slider w3-banner jarallax">
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="assets/images/video.mp4" type="video/mp4" />
        </video>
      </section>
      {/*Main Sllider Start */}
      {/*About One Start*/}
      <section className="about-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="about-one__left pt-4 mt-4">
                <div className="about-one__img">
                  <img src="assets/images/About1.jpg" alt="" />
                </div>
                <div className="pt-4 mt-4 ">
                  <Link to="/About" className="thm-btn contact-one__form-btn">
                    Learn More About Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="about-one__right">
                <div className="section-title text-left">
                  <span className="section-title__tagline">
                    welcome to {companyname}
                  </span>
                  <h2 className="section-title__title">
                    Turning Challenges into Opportunities..
                  </h2>
                </div>
                <div className="about-one__experience-and-points">
                  <div className="about-one__experience-points-box">
                    <p className="about-one__experience-points-text">
                      Our primary objective revolves around meeting the unique
                      requirements of every client we serve in the realm of
                      logistics services. Our company, {companyname}, is
                      committed to upholding the highest standards of quality
                      across various dimensions in the logistics industry. These
                      encompass effective communication, consistent reliability
                      in shipping and transport, unwavering transparency in
                      supply chain management, gracious courtesies in dealing
                      with clients, genuine empathy for the challenges of
                      logistics, swift responsiveness to changing needs,
                      constant availability to address inquiries, and robust
                      security measures to safeguard valuable cargo. Our
                      unyielding determination lies in our continuous efforts to
                      refine and elevate our operational processes and
                      technological infrastructure specifically tailored to
                      logistics services. By doing so, we diligently strive to
                      fulfill our overarching goal of providing exceptional
                      logistics solutions to our clients.
                    </p>
                    <div className="about-one__experience-points">
                      <ul className="about-one__experience-points-list list-unstyled">
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Reliability</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Efficiency</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Global Reach</p>
                          </div>
                        </li>
                      </ul>
                      <ul className="about-one__experience-points-list list-unstyled">
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Expertise</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Safety and Security</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <span className="fa fa-check" />
                          </div>
                          <div className="text">
                            <p>Cost-Effective Solutions</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feature-one">
        <div className="container">
          <div className="row">
            {/*Feature One Single Start*/}
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src="assets/images/Air Freight.jpg" alt="" />
                  </div>
                  <div className="feature-one__hover-content">
                    <div className="feature-one__hover-icon">
                      <span className="fa fa-plane" />
                    </div>
                    <div className="feature-one__hover-content-inner">
                      <div className="feature-one__shape-1">
                        <img
                          src="assets/images/shapes/feature-one-shape-1.png"
                          alt=""
                        />
                      </div>
                      <h3 className="feature-one__hover-title">
                        <Link to="/Air">Air Freight</Link>
                      </h3>
                      <p className="feature-one__hover-text">
                        {" "}
                        Our distinctive position in the logistics industry is
                        firmly rooted in several key assets: a substantial air
                        fleet, a sprawling global network, and robust
                        partnerships with leading commercial airlines.
                      </p>
                    </div>
                    <div className="feature-one__hover-arrow-box">
                      <Link to="/Air" className="feature-one__hover-arrow">
                        <span className="icon-right-arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="feature-one__content">
                  <div className="feature-one__icon">
                    <span className="fa fa-plane" />
                  </div>
                  <h3 className="feature-one__title">
                    <Link to="/Air">Air Freight</Link>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src="assets/images/Ocean Freight.jpg" alt="" />
                  </div>
                  <div className="feature-one__hover-content">
                    <div className="feature-one__hover-icon">
                      <span className="fa fa-ship" />
                    </div>
                    <div className="feature-one__hover-content-inner">
                      <div className="feature-one__shape-1">
                        <img
                          src="assets/images/shapes/feature-one-shape-1.png"
                          alt=""
                        />
                      </div>
                      <h3 className="feature-one__hover-title">
                        <Link to="/Ocean">Ocean Freight</Link>
                      </h3>
                      <p className="feature-one__hover-text">
                        {" "}
                        With an extensive and diverse fleet, bolstered by our
                        skilled personnel operating along the world's major
                        shipping routes, we stand poised to offer our global
                        clientele frequent, secure, and cost-effective ocean
                        freight services.
                      </p>
                    </div>
                    <div className="feature-one__hover-arrow-box">
                      <Link to="/Ocean" className="feature-one__hover-arrow">
                        <span className="icon-right-arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="feature-one__content">
                  <div className="feature-one__icon">
                    <span className="fa fa-ship" />
                  </div>
                  <h3 className="feature-one__title">
                    <Link to="/Ocean">Ocean Freight</Link>
                  </h3>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="feature-one__single">
                <div className="feature-one__img-box">
                  <div className="feature-one__img">
                    <img src="assets/images/Inland Transport.jpg" alt="" />
                  </div>
                  <div className="feature-one__hover-content">
                    <div className="feature-one__hover-icon">
                      <span className="fa fa-truck" />
                    </div>
                    <div className="feature-one__hover-content-inner">
                      <div className="feature-one__shape-1">
                        <img
                          src="assets/images/shapes/feature-one-shape-1.png"
                          alt=""
                        />
                      </div>
                      <h3 className="feature-one__hover-title">
                        <Link to="/Inland">Inland Transport</Link>
                      </h3>
                      <p className="feature-one__hover-text">
                        In the intricate landscape of international trade, the
                        role of inland transportation stands as a linchpin. It's
                        the initial step that sets your goods in motion,
                        ensuring they reach the sea port for their global
                        voyage.
                      </p>
                    </div>
                    <div className="feature-one__hover-arrow-box">
                      <Link to="/Inland" className="feature-one__hover-arrow">
                        <span className="icon-right-arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="feature-one__content">
                  <div className="feature-one__icon">
                    <span className="fa fa-truck" />
                  </div>
                  <h3 className="feature-one__title">
                    <Link to="/Inland">Inland Transport</Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-one">
        <div
          className="services-one__bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/services-one-bg.png)",
          }}
        />
        <div className="container">
          <div className="services-one__top">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="services-one__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Mission Statement
                    </span>
                    <h2 className="section-title__title">
                      {companyname}'s Mission, Objective and Principle
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="services-one__right">
                  <p className="services-one__right-text">
                    We are steadfast in our pursuit of excellence in global
                    transportation. Whether it's the movement of goods by land,
                    sea, or air, we pledge to execute these logistics with
                    precision and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services-one__bottom">
            <div className="row">
              {/*Services One Single Start*/}
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-bullseye" />
                    </div>
                    <h3 className="services-one__title">Our Mission</h3>
                    <p className="services-one__text">
                      Our perpetual commitment is to consistently exceed
                      customer expectations by delivering exceptional global
                      transportation and comprehensive logistics services.
                      <br /> <br />{" "}
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-check" />
                    </div>
                    <h3 className="services-one__title">Our Objective</h3>
                    <p className="services-one__text">
                      We are devoted to upholding our professional identity and
                      values while providing unmatched freight services on a
                      global scale, demonstrating our enduring dedication to
                      excellence.
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-bullseye" />
                    </div>
                    <h3 className="services-one__title">Our Principle</h3>
                    <p className="services-one__text">
                      At {companyname}, our guiding principle is an unwavering
                      commitment to maintaining unwavering quality standards at
                      all times.
                      <br /> <br /> <br />{" "}
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cases-one">
        <div className="cases-one__bg-box" />
        <div
          className="cases-one__bg"
          style={{
            backgroundImage: "url(assets/images/backgrounds/cases-one-bg.png)",
          }}
        />
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">
              Our All Freight Solutions
            </span>
            <h2 className="section-title__title">
              Explore Our <span>Freight </span> <br />
              Solutions
            </h2>
          </div>
          <div className="cases-one__bottom">
            <div
              className="cases-one__carousel owl-carousel owl-theme thm-owl__carousel"
              data-owl-options='{
                        "loop": true,
                        "autoplay": true,
                        "margin": 30,
                        "nav": false,
                        "dots": false,
                        "smartSpeed": 500,
                        "autoplayTimeout": 10000,
                        "navText": ["<span class=\"icon-left-arrow\"></span>","<span class=\"icon-right-arrow\"></span>"],
                        "responsive": {
                            "0": {
                                "items": 1
                            },
                            "768": {
                                "items": 1
                            },
                            "992": {
                                "items": 2
                            },
                            "1200": {
                                "items": 2
                            }
                        }
                    }'
            >
              {/*Cases one Single Start*/}
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Air Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Air" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Air">Air Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Ocean Freight.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Ocean" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Ocean">Ocean Freight</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Inland Transport.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Inland" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Inland">Inland Transport</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Third Party Logistics (3PL).jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Third" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Third">Third Party Logistics (3PL)</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Warehousing & Distribution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Warehousing" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Warehousing">
                          Warehousing & Distribution
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Cargo Insurance.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Cargo" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Cargo">Cargo Insurance</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img src="assets/images/Project Management.jpg" alt="" />
                      <div className="cases-one__shape-1">
                        <Link to="/Project" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Project">Project Management</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="cases-one__single">
                  <div className="cases-one__img-box">
                    <div className="cases-one__img">
                      <img
                        src="assets/images/Total Supply Chain Solution.jpg"
                        alt=""
                      />
                      <div className="cases-one__shape-1">
                        <Link to="/Total" className="cases-one__arrow">
                          <span className="icon-right-arrow" />
                        </Link>
                      </div>
                    </div>
                    <div className="cases-one__content">
                      <p className="cases-one__sub-title">Services</p>
                      <h3 className="cases-one__title">
                        <Link to="/Total">Total Supply Chain Solution</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Cases One End*/}
      {/*Why Choose One Start*/}
      <section className="why-choose-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="why-choose-one__left">
                <div className="section-title text-left">
                  <span className="section-title__tagline">Our benefits</span>
                  <h2 className="section-title__title">
                    Why <span>Choose</span> <br />
                    {companyname}
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="why-choose-one__right">
                <ul className="why-choose-one__list list-unstyled">
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fa fa-clock" />
                      </div>
                      <div className="title">
                        <h3>
                          24/7 Support
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fa fa-users" />
                      </div>
                      <div className="title">
                        <h3>
                          Team Work
                        </h3>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="why-choose-one__single">
                      <div className="icon">
                        <span className="fas fa-award" />
                      </div>
                      <div className="title">
                        <h3>
                          Services excellence
                        </h3>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Why Choose One End*/}

      {/*Contact One Start*/}
      <section className="contact-one">
        <div className="contact-one__shape-1 float-bob-x">
          <img src="assets/images/shapes/contact-one-shape-1.png" alt="" />
        </div>
        <div className="contact-one__shape-2">
          <img src="assets/images/shapes/contact-one-shape-2.png" alt="" />
        </div>
        <div className="contact-one__img-1">
          <img src="assets/images/About2.jpg" style={{height:"100%"}} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-4">
              <div className="contact-one__left">
                <div className="contact-one__contact-box">
                  <div
                    className="contact-one__contact-box-bg"
                   
                  />
                  <div className="contact-one__icon">
                    <span className="fa fa-arrow-right" />
                  </div>
                  <p className="contact-one__sub-title">Contact now</p>
                  <h3 className="contact-one__title">
                  Kwun Tong Kowloon, Hong Kong
                  </h3>
                  <p className="contact-one__nummber">
                    {companyemail}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-8">
              <div className="contact-one__right">
                <div className="section-title text-left">
                  <span className="section-title__tagline">
                    contact with us
                  </span>
                 
                </div>
                <div className="contact-one__form-box">
                 <form
              id="contactForm"
              className="contact-one__form contact-form-validated"
              action="/php/thankyou-contact.php "
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="row g-3">
                <div className="col-6">
                  <div className="contact-one__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="contact-one__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-one__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-one__form-input-box">
                    <input
                      className="form-control"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="contact-one__form-input-box">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    className="thm-btn contact-page__form-btn"
                    type="submit"
                    defaultValue=""
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <h5 style={{ color: "red" }}>{result}</h5>
                  <div className="result" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Contact One End*/}
      {/*BLog One Start*/}
      <section className="blog-one">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">Our Industry Sectors</span>
            <h2 className="section-title__title">
              Explore Our <span>Industry</span> <br />
               Sectors
            </h2>
          </div>
          <div className="row">
            {/*Blog One Single Start*/}
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Automotive.jpg" alt="" />
                    <Link to="/Automotive">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Automotive">
                      Automotive
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Electronics.jpg" alt="" />
                    <Link to="/Electronics">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Electronics">
                      Electronics
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Retail.jpg" alt="" />
                    <Link to="/Retail">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Retail">
                      Retail
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/High Tech.jpg" alt="" />
                    <Link to="/High">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/High">
                      High Tech
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Industrial.jpg" alt="" />
                    <Link to="/Industrial">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Industrial">
                      Industrial
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-4 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="blog-one__single">
                <div className="blog-one__img-box">
                  <div className="blog-one__img">
                    <img src="assets/images/Apparel.jpg" alt="" />
                    <Link to="/Apparel">
                      <span className="blog-one__plus" />
                    </Link>
                    
                  </div>
                  <div className="blog-one__content-top">
                   
                    <p className="blog-one__content-sub-title">
                     Industry
                    </p>
                    <h3 className="blog-one__content-title">
                      <Link to="/Apparel">
                      Apparel
                      </Link>
                    </h3>
                  </div>
                </div>
                <div className="blog-one__content">
                  <div className="blog-one__comment-and-btn">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
