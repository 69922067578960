import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Project Management</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Project Management</li>
            </ul>
          </div>
        </div>
      </section>
      {/*Page Header End*/}
      {/*Services Details Start*/}
      <div className="services-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="services-details__sidebar">
                <div className="services-details__services-list">
                  <ul className="services-details__services list-unstyled">
                    <li className="">
                      <Link to="/Air">Air Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Inland">Inland Transport</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Third">Third Party Logistics (3PL)</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Cargo">Cargo Insurance</Link>
                    </li>
                    <li className="active ">
                      <Link to="/Project">Project Management</Link>
                    </li>
                    <li className=" ">
                      <Link to="/Total">Total Supply Chain Solution</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="services-details__right">
                <div className="services-details__img">
                  <img src="assets/images/Project Management.jpg" alt="" />
                </div>
                <h3 className="services-details__title-1">
                  Project Management
                </h3>
                <p className="services-details__text-1">
                  <p>
                    Project freight, often known as project forwarding or
                    project logistics, encompasses the transportation of
                    substantial, intricate, or high-value equipment. This
                    category encompasses break bulk shipments transported across
                    land, sea, or air. The process employs a variety of
                    transportation modes, including cranes, trucks, rail,
                    planes, ships, and trains, to facilitate the movement of
                    single or multiple items. These project cargo undertakings
                    often possess a time-sensitive nature or are bound by strict
                    delivery schedules, rendering them among the most complex
                    and intricate endeavors within the broader logistics sector.
                  </p>

                  <p>
                    A notable facet within this realm is the concept of
                    roll-on/roll-off (RORO or ro-ro) ships. These cargo vessels
                    are engineered to efficiently carry wheeled cargo, such as
                    automobiles, trucks, buses, semi-trailers, trailers, and
                    even railroad cars. These vehicles can be driven onto and
                    off the ship using their own wheels or via a platform
                    vehicle, such as a self-propelled modular transporter. The
                    RORO approach enhances the efficiency of loading and
                    unloading, particularly for wheeled cargo, contributing to
                    smoother logistics processes.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
