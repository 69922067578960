import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Automotive</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Automotive</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Automotive.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">Automotive Industry</h2>
                  <p className="blog-details__text-1">
                    <p>
                      The automobile industry stands as a realm fraught with
                      complex challenges when it comes to support models and
                      provisions. Particularly in a landscape where
                      manufacturing centers span across multiple companies and
                      international borders, alongside intricate in-line
                      production stages, meticulous temporal planning becomes an
                      indispensable element.
                    </p>

                    <p>
                      These complexities are intricately woven into the fabric
                      of the automobile sector, and we, as a diligent player in
                      the field, are acutely cognizant of these challenges and
                      steadfastly committed to addressing them through
                      transparent and effective communication. Our focus is
                      unwaveringly centered on offering robust business support,
                      liberating your bandwidth to excel in your core
                      competencies.
                    </p>

                    <p>
                      Within the sphere of automotive supply, we pride ourselves
                      on harboring the industry's finest talent, fortified by a
                      truly global presence. Our adept service team and a
                      well-knit communication network have adeptly navigated an
                      array of challenges - spanning from strategic location
                      selection to the nuanced demands of the world's largest
                      automotive giants. The efficacy of our management
                      structure, combined with our expansive global network,
                      ensures that accountability is a constant. From our
                      industry experts who dwell within the business intricacies
                      to the adept hands that manage operations on the warehouse
                      floor, you can rest assured that you're in capable hands.
                    </p>

                    <p>
                      With us as your partner, the automotive industry's
                      multifaceted challenges are met with an unwavering
                      commitment to precision, efficiency, and excellence.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
