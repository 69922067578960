import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{ backgroundImage: "url(assets/images/banner.jpg)" }}
        ></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Food & Beverages</h2>
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <span>/</span>
              </li>
              <li>Food & Beverages</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              <div className="blog-details__left">
                <div className="blog-details__img">
                  <img
                    src="assets/images/Food & Beverages.jpg"
                    style={{ height: "400px" }}
                    alt=""
                  />
                </div>
                <div className="blog-details__content">
                  <h2 className="text-center  p-2">
                    Food & Beverages Industry
                  </h2>
                  <p className="blog-details__text-1">
                    <p>
                      Propelled by a dynamic team of professionals, our
                      enterprise is dedicated to delivering robust solutions in
                      the realm of food and beverage logistics. We specialize in
                      providing a seamless experience, leveraging
                      temperature-controlled environments and cold chain storage
                      to ensure the integrity of your products. Our commitment
                      lies in extending the longevity of food items while
                      preserving their utmost freshness.
                    </p>

                    <p>
                      Our service spectrum encompasses an array of packaging and
                      storage alternatives, catering to diverse food products.
                      Through our tailored solutions, we enhance shelf life and
                      uphold optimal freshness standards. Utilizing cutting-edge
                      cross-docking systems, we expedite the transit of
                      perishable and temperature-sensitive food items to their
                      intended destinations, ensuring both speed and
                      reliability.
                    </p>

                    <p>
                      Our dedication to excellence is further mirrored in our
                      comprehensive supply chain visibility. This transparency
                      empowers you to harness the benefits of heightened
                      production, reduced inventories, and, significantly,
                      enhanced order fulfillment. Moreover, our proficiency
                      extends to secure and dependable transportation, ensuring
                      the safe movement of meals and beverages. When you partner
                      with us, you're embracing a solution that is not only
                      efficient but also trustworthy, delivering your goods with
                      the utmost care and reliability.
                    </p>
                  </p>
                  <div className="blog-details__bottom"></div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="sidebar">
                <div className="sidebar__single sidebar__category">
                  <h3 className="sidebar__title">Industry Sectors</h3>
                  <ul className="sidebar__category-list list-unstyled">
                    <li>
                      <Link to="/Automotive">Automotive</Link>
                    </li>
                    <li>
                      <Link to="/Electronics">Electronics</Link>
                    </li>
                    <li>
                      <Link to="/Retail">Retail</Link>
                    </li>
                    <li>
                      <Link to="/High">High Tech</Link>
                    </li>
                    <li>
                      <Link to="/Industrial">Industrial</Link>
                    </li>
                    <li>
                      <Link to="/Apparel">Apparel</Link>
                    </li>
                    <li>
                      <Link to="/Healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/Food">Food & Beverages</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
