import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-header">
        <div
          className="page-header-bg"
          style={{
            backgroundImage:
              "url(assets/images/banner.jpg)",
          }}
        ></div>
        <div className="page-header-shape-1">
          <img src="assets/images/shapes/page-header-shape-1.png" alt="" />
        </div>
        <div className="container">
          <div className="page-header__inner">
            <ul className="thm-breadcrumb list-unstyled">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li>
                <span>/</span>
              </li>
              <li>Mission Statement </li>
            </ul>
            <h2>Mission Statement </h2>
          </div>
        </div>
      </section>
      
      <section className="services-one">
        <div
          className="services-one__bg"
          style={{
            backgroundImage:
              "url(assets/images/backgrounds/services-one-bg.png)",
          }}
        />
        <div className="container">
          <div className="services-one__top">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="services-one__left">
                  <div className="section-title text-left">
                    <span className="section-title__tagline">
                      Mission Statement
                    </span>
                    <h2 className="section-title__title">
                      {companyname}'s Mission, Objective and Principle
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="services-one__right">
                  <p className="services-one__right-text">
                    We are steadfast in our pursuit of excellence in global
                    transportation. Whether it's the movement of goods by land,
                    sea, or air, we pledge to execute these logistics with
                    precision and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="services-one__bottom">
            <div className="row">
              {/*Services One Single Start*/}
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-bullseye" />
                    </div>
                    <h3 className="services-one__title">Our Mission</h3>
                    <p className="services-one__text">
                      Our perpetual commitment is to consistently exceed
                      customer expectations by delivering exceptional global
                      transportation and comprehensive logistics services.
                      <br /> <br />{" "}
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-check" />
                    </div>
                    <h3 className="services-one__title">Our Objective</h3>
                    <p className="services-one__text">
                      We are devoted to upholding our professional identity and
                      values while providing unmatched freight services on a
                      global scale, demonstrating our enduring dedication to
                      excellence.
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="services-one__single">
                  <div className="services-one__content">
                    <div className="services-one__icon">
                      <span className="fa fa-bullseye" />
                    </div>
                    <h3 className="services-one__title">Our Principle</h3>
                    <p className="services-one__text">
                      At {companyname}, our guiding principle is an unwavering
                      commitment to maintaining unwavering quality standards at
                      all times.
                      <br /> <br /> <br />{" "}
                    </p>
                  </div>
                  <div className="services-one__btn-box">
                    <Link to="/Missionvision" className="services-one__btn">
                      <span className="icon-right-arrow" />
                      More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
