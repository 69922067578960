import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <div className="page-wrapper">
        {/*Site Footer Start*/}
        <footer className="site-footer">
          <div className="site-footer__shape-1 float-bob-y"></div>
          <div className="site-footer__top">
            <div className="container">
             
            </div>
          </div>
          <div className="container">
            <div className="site-footer__middle">
              <div className="row">
                <div
                  className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="100ms"
                >
                  <div className="footer-widget__column footer-widget__about">
                    <div className="footer-widget__logo">
                      <Link to="/">
                        <img src="assets/images/logo.png" height={80} alt="" />
                      </Link>
                    </div>
                    <h3 className="text-white">
                    Solutions for Today's Logistics Challenges.
                    </h3>
                    <p>Our primary objective revolves around meeting the unique requirements of every client we serve in the realm of logistics services. </p>
                  </div>
                </div>

                <div
                  className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div className="footer-widget__column footer-widget__Contact">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Contact</h3>
                    </div>
                    <ul className="footer-widget__Contact-list list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="fas fa-map-marker" />
                        </div>
                        <div className="text">
                          <span>Visit Office</span>
                          <p>{companyaddress}</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="fas fa-envelope" />
                        </div>
                        <div className="text">
                          <span>Send Email</span>
                          <p>
                            <Link to="mailto:{companyemail}">
                              {companyemail}
                            </Link>
                          </p>
                        </div>
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <div className="footer-widget__column footer-widget__newsletter">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">Newsletter</h3>
                    </div>
                    <p className="footer-widget__newsletter-text">
                      Subscribe our newsletter to get latest updates
                    </p>
                    <form
                      className="footer-widget__email-box"
                      action="/php/thankyou-subscribe.php "
                      method="POST"
                      onSubmit={(event) => handleSubmit(event)}
                    ><div className="footer-widget__email-input-box">
                      <input
                        onChange={(event) => handleSubemailChange(event)}
                        id="subemail"
                        value={subemail}
                        type="email"
                        name="subemail"
                        placeholder="Your email address"
                        required
                        className=""
                      /></div>
                      <button
                        className="footer-widget__subscribe-btn thm-btn"
                        type="submit"
                        name="submit"
                      >
                        Subscribe Now
                      </button>
                    </form>
                    <h5 style={{color:"red"}}>{result}</h5>
                    <div className="mc-form__response" />
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 pt-4 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="footer-widget__column footer-widget__link">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">
                        Useful Links {" >>"}
                      </h3>
                    </div>
                    <ul className="footer-widget__link-list list-unstyled">
                      <li>
                        <Link to="/Home">Home</Link>
                      </li>
                      <li>
                        <Link to="/About">About Us</Link>
                      </li>
                      <li>
                        <Link to="/Whyus">Why us</Link>
                      </li>
                      <li>
                        <Link to="/Services">Services</Link>
                      </li>

                      <li>
                        <Link to="/Careers">Join Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 pt-4 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="footer-widget__column footer-widget__link">
                    <div className="footer-widget__title-box"></div>
                    <ul className="footer-widget__link-list list-unstyled">
                      <li>
                        <Link to="/Industries">Industry Sectors</Link>
                      </li>
                      <li>
                        <Link to="/Privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/Terms">Terms</Link>{" "}
                      </li>
                      <li>
                        <Link to="/Contact">Contact</Link>
                      </li>
                      <li>
                        <Link to="/Getquote">Get Quote</Link>
                      </li>
                      <li>
                        <Link to="/Track">Track Shipment</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 pt-4 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="footer-widget__column footer-widget__link">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title">
                        Our Services {" >>"}
                      </h3>
                    </div>
                    <ul className="footer-widget__link-list list-unstyled">
                      <li>
                        <Link to="/Air">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Ocean">Ocean Freight</Link>
                      </li>
                      <li>
                        <Link to="/Inland">Inland Transport</Link>
                      </li>
                      <li>
                        <Link to="/Third">Third Party Logistics (3PL)</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 pt-4 wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="footer-widget__column footer-widget__link">
                    <div className="footer-widget__title-box">
                      <h3 className="footer-widget__title"></h3>
                    </div>
                    <ul className="footer-widget__link-list list-unstyled">
                      <li>
                        <Link to="/Warehousing">
                          Warehousing & Distribution
                        </Link>
                      </li>
                      <li>
                        <Link to="/Air">Cargo Insurance</Link>
                      </li>
                      <li>
                        <Link to="/Project">Project Management</Link>
                      </li>
                      <li>
                        <Link to="/Total">Total Supply Chain Solution</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="site-footer__bottom-inner">
                    <p className="site-footer__bottom-text">
                      © Copyright 2023 by <Link to="/">{companyname}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*Site Footer End*/}
      </div>
      {/* /.page-wrapper */}
      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler" />
        {/* /.mobile-nav__overlay */}
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i className="fa fa-times" />
          </span>
          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src="assets/images/logo.png" width={135} alt="" />
            </Link>
          </div>
          {/* /.logo-box */}
          <div className="mobile-nav__container" />
          {/* /.mobile-nav__container */}
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope" />
              <Link to="mailto:{companyemail}">{companyemail}</Link>
            </li>
          </ul>
          {/* /.mobile-nav__contact */}
          <div className="mobile-nav__top">
           
            {/* /.mobile-nav__social */}
          </div>
          {/* /.mobile-nav__top */}
        </div>
        {/* /.mobile-nav__content */}
      </div>
      {/* /.mobile-nav__wrapper */}
    </>
  );
};

export default Footer;
